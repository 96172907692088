import { Icon } from '@fluentui/react/lib/Icon';
import { List } from '@fluentui/react/lib/List';
import React from 'react';
import { useFetchWikiList } from '../../../hooks';
import { WikiEntity } from '../../../types';
import {Empty} from './Empty';

const onRenderCell = (item?: WikiEntity, index?: number): JSX.Element => {
    if (item) {
        return (
            <div data-is-focusable={true}>
              <div>
                <div>{item.name}</div>
                <div>{`Item Version ${item.version}`}</div>
                <div>{item.text}</div>
              </div>
              <Icon iconName={'ChevronRight'} />
            </div>
          );
    }
    return <></>;
  };

const WikiList = (props: {userId?: string}) => {
    const items = useFetchWikiList(props.userId);
    return <section>
        {items.length > 0 ? <List items={items} onRenderCell={onRenderCell} /> : <Empty />}
    </section>
}

export { WikiList };