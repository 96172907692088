import React, { useCallback } from 'react';
import MDEditor from '@uiw/react-md-editor';
import { DefaultButton, Pivot, PivotItem } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import './content.css';
import { WikiList } from '../../Features/WikiList/WikiList';

export interface ContentProps {
    googleId?: string
}

const stackTokens: IStackTokens = { childrenGap: 12 };

interface NameInputProps {
    value: string;
    errorMessage: string;
}

const defaultNameInput: NameInputProps = {
    value: '',
    errorMessage: ''
}

const Content = (props: ContentProps) => {
    const [value, setValue] = React.useState("");
    const [name, setName] = React.useState<NameInputProps>(defaultNameInput);
    const {googleId = ''} = props;
    const onSave = useCallback(async () => {
        if (googleId) {
            //https://api-wiki-vaishnavthakur.azurewebsites.net
            await fetch(`http://localhost:7071/api/wiki/${googleId}`, {
                method: 'POST',
                body: JSON.stringify({
                    'Name': name.value,
                    'UserId': googleId,
                    'BlobUrl': value,
                    'PartitionKey': googleId
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.ok) {
                    console.log('Wiki saved');
                }
                else {
                    console.error('Error happens while saving the wiki. Try again after some time.');
                }
            });
        }
        
    }, [googleId, name, value]);

    const onNameChange = useCallback((e: any) => {
        setName({
            value: e?.target?.value || '',
            errorMessage: e?.target?.value === '' ? 'Name is required!' : ''
        });
    }, []);

    return (<section className="content-container">
            <section className="main-content-container">
                <Pivot aria-label="Large Link Size Pivot Example" linkSize="large">
                    <PivotItem headerText="Editor">
                        <section className="md-editor">
                            <Stack tokens={stackTokens} horizontalAlign="space-evenly">
                                <TextField label="Name" {...name} onChange={onNameChange} required placeholder="Enter wiki name..." />
                                <MDEditor 
                                    extraCommands={[]} 
                                    preview={'edit'} 
                                    visiableDragbar={false}
                                    height={350} 
                                    value={value} 
                                    onChange={(e) => setValue(e || '')} />
                            </Stack>
                        </section>
                    </PivotItem>
                    <PivotItem headerText="Preview">
                        <section className="md-preview">
                            <MDEditor.Markdown source={value} />
                        </section>
                    </PivotItem>
                    <PivotItem headerText="File List">
                        <WikiList userId={googleId} />
                    </PivotItem>
                </Pivot>
                <section className="action-container">
                    {googleId && <DefaultButton text="Save" onClick={onSave} allowDisabledFocus disabled={value === '' || name?.errorMessage !== ''} /> }
                </section>
            </section>
        </section>)
}

export default Content;