import { mergeStyleSets } from '@fluentui/react';
import { IShimmerStyleProps, IShimmerStyles, Shimmer } from '@fluentui/react/lib/Shimmer';
import React from 'react';

const classNames = mergeStyleSets({
    wrapper: {
      selectors: {
        '& > .ms-Shimmer-container': {
          margin: '15px 0',
        },
      },
    }
  });

  const getShimmerStyles = (props: IShimmerStyleProps): IShimmerStyles => {
    return {
      shimmerWrapper: [
        {
          backgroundColor: '#e0e0e0',
          height: '30px',
        },
      ],
      shimmerGradient: [
        {
          backgroundColor: '#e0e0e0',
          
          backgroundImage:
            'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #f3f2f1 50%, rgba(255, 255, 255, 0) 100%)',
        },
      ],
    };
  };
  
const renderItems = new Array(10).fill(1);
const Empty = () => {
    return <div className={classNames.wrapper}>
        {renderItems.map(item => {
            return <Shimmer width="100%" styles={getShimmerStyles} /> 
        })}
  </div>
}

export { Empty };