import React from 'react';
import './footer.css';

const Footer = () => {
    return (<footer id="footerContainer" className="footer-container">
            <section className="row-1">
                { `All rights reserved to www.wiki.vaishnavthakur.com` }
            </section>
            <section  className="row-2">Contact us - wiki@vaishnavthakur.com</section>   
        </footer>
    );
}

export default Footer;