import React, {useState} from 'react';
import Content from './components/Layout/Content/Content';
import Header from './components/Layout/Header/Header';
import Footer from './components/Layout/Footer/Footer';
import './app.css';
import { GoogleAuthDetails } from './types';
import { getRumClient } from './utils';

getRumClient();

function App() {
  const [state, setState] = useState<GoogleAuthDetails>();
  return (
    <>
      <Header 
        setUserData={setState}
        googleAuthDetails={state}
      />
      <Content googleId={state?.googleId} />
      <Footer />
    </>
  );
}

export default App;
