import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export const getRumClient = () => {
    try {
        const config: AwsRumConfig = {
            allowCookies: true,
            enableXRay: true,
            identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
            guestRoleArn: process.env.REACT_APP_GUEST_ROLE_ARN,
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['performance', 'errors', 'http']
        };
    
        return new AwsRum(process.env.REACT_APP_RUM_APP_ID!, '1.0.0', 'us-west-2', config);    
    }
    catch(e) { console.log(e); }
}