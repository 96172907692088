import React, {useEffect, useCallback, useState} from 'react';
import './header.css';
import { Persona, PersonaSize, PersonaPresence, IIconProps } from '@fluentui/react';
import { GoogleAuthDetails } from '../../../types';
import { gapi } from 'gapi-script';
import { IconButton, CommandBarButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { useBoolean } from '@fluentui/react-hooks';
import { Panel } from '@fluentui/react/lib/Panel';

const addIcon: IIconProps = { iconName: 'Signin' };
const signOutIcon: IIconProps = { iconName: 'SignOut' };

export interface HeaderProps {
    googleAuthDetails?: GoogleAuthDetails
    setUserData: (googleAuthDetails: GoogleAuthDetails) => void;
}

// Client ID and API key from the Developer Console
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;

// Array of API discovery doc URLs for APIs
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/drive.appdata';

const Header = ({googleAuthDetails, setUserData}: HeaderProps) => {
    const {googleId = '', accessToken = ''} = googleAuthDetails || {};
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState<boolean>(false);
    const [docs, setDocuments] = useState<Array<any>>([]);
    const [authToken, setAuthToken] = useState('');
    const handleAuthClick = useCallback(() => {
        gapi.auth2.getAuthInstance().signIn();
    }, []);

    const handleSignOutClick = useCallback(() => {
      gapi.auth2.getAuthInstance().signOut();
      fetch(`${process.env.REACT_APP_BACK_END_API_URL}/.auth/logout?post_logout_redirect_uri=${encodeURI('https://wiki.vaishnavthakur.com')}`, {
        mode: 'no-cors'
      })
      .then((req) => {
        console.log(req);
        gapi.auth2.getAuthInstance().signOut();
      });
    }, []);

    const listFiles = useCallback(async () => {
      if (googleId) {
        console.log(accessToken);
        setIsFetchingGoogleDriveFiles(true);

        const auth = authToken || await fetch(`${process.env.REACT_APP_BACK_END_API_URL}/.auth/login/google`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ "id_token": accessToken })
        }).then((req) => {
            if (req.ok) return req.json();
            return {authenticationToken: ''};
        }).then(req => req.authenticationToken);

        setAuthToken(auth);

        const data = await fetch(`${process.env.REACT_APP_BACK_END_API_URL}/api/wiki/${googleId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-ZUMO-AUTH': auth
          }
        }).then(response => {
          if(response.ok) return response.json();
          else console.log(response); return [];
        });

        setDocuments(data);
        setIsFetchingGoogleDriveFiles(false);
      }
    }, [googleId, accessToken, authToken]);

    useEffect(() => {
      (async () => {
        if (isOpen) {
          await listFiles();
        }
      })();
    }, [isOpen, listFiles]);
    
    useEffect(() => {
      const updateSigninStatus = (isSignedIn: boolean) => {
        if (isSignedIn) {
          // Set the signed in user
          const currentUser = gapi.auth2.getAuthInstance().currentUser?.get();
          const profile = currentUser?.getBasicProfile();
          const id_token = currentUser?.getAuthResponse()?.id_token;
          
          if (profile) {
            const response: GoogleAuthDetails = {
                accessToken: id_token,
                email: profile.getEmail(),
                familyName: profile.getFamilyName(),
                givenName: profile.getGivenName(),
                googleId: profile.getId(),
                name: profile.getName(),
                imageUrl: profile.getImageUrl()
            }

            setUserData(response);
          }
          else {
              console.error('Error happens while logging in using Google authenticator. Try again after sometime.');
          }
        } else {
          // prompt user to sign in
          handleAuthClick();
        }
      };

      const initClient = () => {
        gapi.client
          .init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES,
          })
          .then(
            function () {
              // Listen for sign-in state changes.
              gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
    
              // Handle the initial sign-in state.
              updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
            },
            function (error: any) {}
          );
      };

      gapi.load('client:auth2', initClient);
    }, [handleAuthClick, setUserData]);

    const onRenderFooterContent = React.useCallback(
      () => (
        <div className="custom-panel-footer">
          <CommandBarButton iconProps={signOutIcon} text="Logout" onClick={handleSignOutClick} />
        </div>
      ),
      [handleSignOutClick],
    );

    return (<header className="header-container">
            <section className="logo-container">
                <Icon iconName="Album" />
                <span className="header-text">Wiki</span> 
            </section>
            <section className="login-container">
                {!googleAuthDetails ?
                  <IconButton
                    className={'color-white'}
                    iconProps={addIcon} 
                    text="Login" 
                    menuProps={{
                      items:[{
                        key: 'logIn',
                        text: 'Sign In',
                        onClick: handleAuthClick,
                        iconProps: { iconName: 'Mail' }
                      }]
                    }} 
                  />: 
                    <Persona
                      size={PersonaSize.size32}
                      onClick={openPanel}
                      presence={PersonaPresence.online}
                      imageAlt={googleAuthDetails.name}
                      imageUrl={googleAuthDetails.imageUrl}
                      imageInitials={`${googleAuthDetails.givenName[0]}${googleAuthDetails.familyName[0]}`}
                    />
                }
            </section>
            <Panel
              isOpen={isOpen}
              onDismiss={dismissPanel}
              headerText={`Welcome ${googleAuthDetails?.givenName} ${googleAuthDetails?.familyName}`}
              closeButtonAriaLabel="Close"
              onRenderFooterContent={onRenderFooterContent}
              isFooterAtBottom={true}
            >
              {isFetchingGoogleDriveFiles && <span>loading files...</span>}
              {!isFetchingGoogleDriveFiles && <ul className="file-list-container">
                {docs.map(item => <li>{item.name}</li>)}
                </ul>}
            </Panel>
        </header>
    )
}

export default Header;
