import { useEffect, useState } from 'react';
import { WikiEntity } from '../../types';

const useFetchWikiList = (userId?: string): Array<WikiEntity> => {
    const [data, setData] = useState<Array<WikiEntity>>([]);
    useEffect(() => {
        if (userId) {
            (async () => {
                const userData = await fetch(`http://localhost:7071/api/wiki/${userId}`, {
                    method: 'GET'
                }).then(response => {
                    if(response.ok) return response.json();
                    else console.log(response); return [];
                });

                setData(userData);
            })();
        }
    }, [userId]);

    return data;
};

export { useFetchWikiList };